







import { Component, Mixins, Prop } from 'vue-property-decorator'
import VLoadSpinner from '~/components/VLoadSpinner.vue'
import { ProductPageStore } from '@elitepage/store/product-page'
import { AbstractFinishPaypal } from '~/mixins/finishPaypal'

@Component({ components: { VLoadSpinner } })
export default class ProductCheckoutFinishPaypal extends Mixins(AbstractFinishPaypal) {
    @Prop({ type: String }) producerSlug!: IProducer['slug']

    @ProductPageStore.Action('loadProductPage') loadProductPage: (producerSlug: IProducer['slug']) => void

    async mounted() {
        await this.loadProductPage(this.producerSlug)
    }
}
